@import '../../style/variables';

.task-list {
  .no-task-warning {
    @include set-typeface('MarkPro-Light');
    font-weight: 300;
    line-height: 35px;
    font-size: 36px;
    color: $secondary-text;
  }
  .view-wrapper {
    margin-top: 70px;
  }
}
