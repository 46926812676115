@import '../../style/variables';

.paginated-table {
  .table-header {
    .table-head {
      @include set-typeface('MarkPro-Bold');
      font-weight: bold;
      color: $primary-text;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.6px;
      padding: 0 50px;
    }
  }
  .table-body {
    .table-row {
      @include set-typeface('MarkPro-Regular');
      font-weight: 400;
      color: $primary-text;
      font-size: 12px;
      line-height: 17px;
    }
  }
}
