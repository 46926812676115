@import '../../style/variables';

.table-view {
  overflow: hidden;
  .table-head {
    @include set-typeface('MarkPro-Bold');
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.6px;
    color: $primary-text;
  }
  .table-row {
    .table-data {
      @include set-typeface('MarkPro-Regular');
      font-weight: 400;
      font-size: 14px;
      color: $primary-text;
      max-width: 400px;
      line-height: 24px;
      padding-top: 18px;
      box-sizing: border-box;
      vertical-align: top;
      border-bottom: 1px solid $gray;
    }
  }
  // User detail section card
  .user-card {
    width: 195px;
  }
  .reporter-user-details {
    display: block;
    margin-bottom: 15px;
  }
  .btn-actions {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    font-size: 16px;
    text-transform: unset;
    width: unset;
  }
}
