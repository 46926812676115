img {
  &.emoji-sm {
    height: 20px;
    width: 20px;
    margin: 0 1px;
  }
  &.emoji-lg {
    height: 40px;
    width: 40px;
    vertical-align: text-bottom;
    margin: 0 1px;
  }
}
