@import '../../../style/variables';

.confirmation-text {
  @include set-typeface('MarkPro-Medium');
  font-weight: 500;
  font-size: 14px;
  color: $primary;
  letter-spacing: -0.36px;
  line-height: 28px;
  margin-bottom: 50px;
  box-sizing: border-box;
}

.button-panel {
  font-size: 12px;
  .btn-left {
    float: left;
  }
  .btn-right {
    float: right;
  }
  &::after {
    clear: both;
  }
}
