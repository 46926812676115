@import '../../style/variables';

.button {
  @include set-typeface('MarkPro-Regular');
  font-weight: bold;
  border-radius: 42.5px;
  outline: none;
  padding: 12px $zero;
  box-sizing: border-box;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}
.size-small {
  width: 70px;
}
.size-medium {
  width: 150px;
}
.size-large {
  width: 250px;
}
.size-link {
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 23px;
  padding: 10px 20px;
  background-color: transparent;
}
.size-fat {
  box-sizing: border-box;
  width: 195px;
  height: 60px;
  border-radius: 30px;
  padding: 20px 30px 20px 30px;
}
.size-xlarge {
  width: 300px;
}
.btn-primary {
  background-color: $primary;
  color: $white-color;
  border: 3px solid $primary;
}
.btn-primary-outline {
  border: 3px solid $primary;
  color: $primary;
  background: transparent;
}
.btn-primary-disabled {
  background-color: $gray;
  color: $secondary-text;
  font-weight: bold;
  border: 3px solid $gray;
  cursor: not-allowed;
}
.primary-link {
  text-decoration: none;
  color: $primary;
}
.btn-secondary {
  display: inline-block;
  position: relative;
  color: $secondary-button-color;
  background: transparent;
}
.btn-empty {
  background: transparent;
}
.btn-icon {
  vertical-align: middle;
  margin-right: 8px;
}
.btn-text {
  vertical-align: middle;
}
