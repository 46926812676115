@import '../../style/variables';

.content-block {
  width: 300px;
  display: block;

  .image-section {
    display: inline-block;
    width: 150px;
    height: 150px;
    margin-right: 10px;
    box-sizing: content-box;

    img {
      width: 100%;
    }
    &.badge-image {
      width: 85px;
    }
    &.badge-image img {
      max-width: 75px;
      vertical-align: middle;
    }
  }

  .content-section {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    vertical-align: top;
    &.badge-section {
      @include set-typeface('MarkPro-Bold');
      font-weight: bold;
      width: 75%;
      vertical-align: middle;
    }
  }

  .read-more {
    @include set-typeface('MarkPro-Bold');
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 24px;
    margin-top: 7px;
    color: $secondary-button-color;
    cursor: pointer;
  }

  .appreciated {
    color: $primary-text;
    line-height: 20px;
    margin-bottom: 22px;
    .target {
      @include set-typeface('MarkPro-Bold');
      font-weight: bold;
    }
  }

  .badge-name {
    @include set-typeface('MarkPro-Bold');
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    width: calc(100% - 160px);
    max-width: 300px;
    vertical-align: middle;
  }
  .sticker-section {
    margin-bottom: 22px;
    .sticker {
      display: inline-block;
      margin-right: 5px;
    }
    .emoji-unicode {
      line-height: 40px;
      font-size: 40px;
    }
    .sticker-title {
      line-height: 40px;
      vertical-align: top;
    }
    span {
      @include set-typeface('MarkPro-Bold');
      font-weight: bold;
      font-size: 13px;
      letter-spacing: 0.8px;
      line-height: 18px;
      color: $header-title;
      text-transform: uppercase;
    }
  }

  .content-text {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $primary-text;
  }

  // Card style
  &.card-content-block {
    width: 100%;

    .image-section {
      display: inline-block;
      width: 100%;
      height: auto;
      text-align: center;
      margin-right: $zero;
      padding: $zero 30px;
      box-sizing: border-box;
      overflow: hidden;
    }
    .badge-image {
      display: inline-block;
      width: 30%;
      padding: $zero 15px $zero 30px;
      text-align: left;
      box-sizing: border-box;
      vertical-align: middle;
    }
    .content-section {
      width: 100%;
      margin-top: 10px;
    }

    .badge-name {
      display: inline-block;
      width: calc(100% - 140px);
      padding-right: 30px;
      box-sizing: border-box;
    }
  }
}
