@import './variables';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.search-bar {
  &::placeholder {
    color: $secondary-text;
  }
}

.dropdown {
  &.ui {
    &.inline {
      font-size: 12px;
      min-width: 110px;
      position: relative;

      .text {
        @include set-typeface('MarkPro-Regular');
        font-weight: 400;
      }

      .dropdown {
        &.icon {
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 18px;
        }
      }

      .dropdown-panel {
        &.menu {
          margin: 6px $zero;
          box-shadow: $zero $zero 6px 4px $shadow;
          border-radius: 2px;
          padding: 14px $zero;

          .dropdown-text {
            &.item {
              font-size: 12px;
              padding: 12px !important;
              min-width: 130px;
              background: none;
              position: relative;

              .tick-mark {
                position: absolute;
                top: 50%;
                transform: translateY(-40%);
                margin: $zero 8px;
                right: 4px;
              }
            }
          }
        }
      }
    }
  }
}

// Table border fix
.ui.table.basic {
  border-collapse: collapse;

  tr {
    th {
      border: none;
    }
  }
}

// Word break for all table
td {
  word-wrap: break-word;
}

.paginated-table {
  .table-header {
    tr {
      th.table-head {
        padding: $zero 20px;
      }
    }
  }
  .table-body {
    .table-row {
      .table-cell {
        padding: 20px;
        vertical-align: middle;
        line-height: 20px;
        border-bottom: 1px solid $gray;
        &:last-child {
          padding: $zero 20px $zero;
        }

        .table-data {
          max-width: 100px;
          white-space: normal;
        }
      }
    }
  }
}

table.search-table {
  thead.table-head {
    tr {
      th {
        @include set-typeface('MarkPro-Bold');
        font-weight: bold;
        color: $primary-text;
      }
    }
  }
}

.DateRangePicker {
  border: none !important;
  width: 100%;

  .DateRangePicker__Month {
    width: 245px;
    margin: auto;
    display: block;
  }

  .DateRangePicker__MonthDates {
    border: none !important;

    .DateRangePicker__WeekdayHeading {
      @include set-typeface('MarkPro-Regular');
      font-weight: 400;
      border: none !important;
    }

    .DateRangePicker__Week {
      border: none !important;
    }

    .DateRangePicker__Date {
      border: none !important;
    }
  }

  .DateRangePicker__PaginationArrow:hover {
    background: none;
  }

  .DateRangePicker__PaginationArrowIcon {
    background-size: contain;
    width: 26px;
    height: 26px;
    top: 50%;
    left: 50%;
    color: $primary;
    border: 6px solid $gray;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-sizing: border-box;

    &.DateRangePicker__PaginationArrowIcon--previous {
      background: url('./assets/images/previous.png') no-repeat;
      background-size: contain;
      background-color: $gray;
      border-left: 8px solid $gray;
    }

    &.DateRangePicker__PaginationArrowIcon--next {
      background: url('./assets/images/next.png') no-repeat;
      background-size: contain;
      border-left: 9px solid $gray;
      background-color: $gray;
    }
  }

  .DateRangePicker__MonthHeader {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    font-size: 13px;
    color: $primary;
  }

  .DateRangePicker__Date--weekend {
    background: none;
  }

  .DateRangePicker__CalendarSelection {
    &.DateRangePicker__CalendarSelection--single {
      background-color: $date-picker-single-selection;
      border-color: $date-picker-single-selection;
      border-radius: 50%;
    }
  }

  .DateRangePicker__Date--is-highlighted {
    border-color: $date-picker-single-selection;
  }

  .DateRangePicker__CalendarSelection {
    background-color: $gray;
    border-color: $gray;

    &.DateRangePicker__CalendarSelection--start {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      background-color: $date-picker-double-selection;
      border-color: $date-picker-double-selection;
      left: 5px;
    }

    &.DateRangePicker__CalendarSelection--end {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      background-color: $date-picker-single-selection;
      border-color: $date-picker-single-selection;
    }
  }

  .DateRangePicker__CalendarHighlight--single {
    border-radius: 50%;
    border-color: $date-picker-double-selection;
  }

  .DateRangePicker__CalendarSelection--is-pending {
    background-color: $gray;
  }

  .DateRangePicker__Date--is-selected {
    color: $primary;
  }

  .DateRangePicker__Date--today {
    color: $badge-bg;
  }
}

.content-block {
  &.card-content-block {
    .appreciated,
    .sticker-section,
    .content-text {
      padding: $zero 30px;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}
