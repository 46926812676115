@import '../../../style/variables';

.card {
  font-size: 14px;
  line-height: 23px;
  color: $primary-text;
  background-color: $white-color;
  margin-bottom: 50px;
  box-sizing: border-box;

  .card-contain {
    padding: 28px $zero;
    width: 100%;
    box-sizing: content-box;
  }

  .card-header {
    display: block;
    padding: 25px 30px $zero;
    .left-section {
      display: inline-block;
      width: 80%;
    }
    .right-section {
      display: inline-block;
      width: 20%;
      vertical-align: top;
      text-align: right;
      cursor: pointer;
    }

    .reveal-title {
      @include set-typeface('MarkPro-Medium');
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      color: $header-title;
    }
  }

  .card-contain {
    min-height: 80px;
  }

  .card-reveal {
    padding: 26px $zero;

    .separator {
      height: 1px;
      width: 100%;
      background-color: $gray;
    }
  }

  .no-reporters {
    color: $secondary-text;
    padding: 15px 30px;
  }

  .reporter {
    margin: 32px 30px $zero;
    position: relative;
    .reported-time {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(50%);
    }
  }
}
