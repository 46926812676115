@import '../../../style/variables';

.report-options-panel {
  margin-top: 52px;
  position: relative;

  .btn-primary {
    margin: 14px $zero;
    font-size: 13px;
    line-height: 11px;
    float: right;
  }
}

.report-summary-table {
  margin-top: 48px;

  .action-removed {
    color: $remove-color;
  }

  .action-allowed {
    color: $allow-color;
  }
}

.report-export-panel {
  position: fixed;
  width: 100%;
  bottom: $zero;
  left: $zero;
  text-align: center;
  margin-bottom: 3%;
  box-sizing: border-box;
  font-size: 13px;

  .btn-left {
    margin-right: 24px;
  }

  .btn-right {
    margin-left: 24px;
  }
}
