@import '../../../style/variables';

.datepicker-drop-down {
  .selected-item {
    padding: 7px 24px;
    position: relative;
    cursor: pointer;
  }

  .display-text {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    font-size: 13px;
    color: $header-title;
    line-height: 26px;
  }

  .display-icon {
    position: absolute;
    right: $zero;
    padding: $zero 16px;
    line-height: 26px;
  }
}
