@import '../../style/variables';

.pagination-btn {
  display: inline-block;
  .nav-btn {
    padding: 4px;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    background-color: $gray;
    cursor: pointer;

    .btn-icon {
      margin-right: $zero;
      vertical-align: unset;
    }
  }
  .page-details {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    font-size: 13px;
    color: $header-title;
    display: inline-block;
    letter-spacing: 1px;
    margin: 4px;
    .page-count {
      @include set-typeface('MarkPro-Bold');
      font-weight: bold;
    }
  }
}
