@import '../../style/variables';

.loader {
  margin: $zero;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white-color;
  z-index: 1010;
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: $zero 2px 17px $zero $loader-shadow;

  .loader-image {
    box-sizing: border-box;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 24px;
  }

  .loader-text {
    @include set-typeface('MarkPro-Bold');
    font-weight: bold;
    color: $primary;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    text-align: center;
    position: relative;
  }
}
