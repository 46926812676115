@import '../../style/variables';

.report-container {
  .title {
    width: 100%;
  }

  .report-options {
    margin-top: 100px;

    .report-input {
      margin-right: 32px;
    }
  }

  .btn-reports {
    margin-top: 100px;
    font-size: 12px;
  }
}
