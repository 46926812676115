@import '../../style/variables';

.modal {
  position: fixed;
  background-color: $white-color;
  width: 70%;
  max-width: 500px;
  border: 1px solid #ccc;
  padding: 64px 4%;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -100%);
  opacity: 0;
  transition: all 0.3s ease-out;
  z-index: -1;
  &.modal-show {
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 1005;
  }
  &.modal-gray {
    background-color: $body-bg;
  }

  .modal-header {
    margin-bottom: 50px;
    display: block;
    line-height: 26px;
    height: 26px;
    box-sizing: border-box;
    position: relative;

    .modal-title {
      @include set-typeface('MarkPro-Bold');
      font-weight: bold;
      font-size: 24px;
      color: $header-title;
      display: inline-block;
      margin: $zero;
    }

    .modal-close {
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: inline-block;
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
