@import '../../../style/variables';

.search-table {
  .table-head {
    th {
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 21px;
    }
  }
  .table-body {
    .search-table-row {
      cursor: pointer;
    }
    td {
      @include set-typeface('MarkPro-Regular');
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
    }
    .post-status {
      display: inline-block;
      vertical-align: middle;
    }
    .table-data {
      width: 180px;
      vertical-align: middle;
      border-bottom: 1px solid $gray;
    }

    .word-wrap {
      max-width: 100px;
      white-space: normal;
    }
  }
}
