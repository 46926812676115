@import './../../../style/variables';

.header-row {
  position: absolute;
  margin: 70px 10%;
  width: 100%;
  box-sizing: border-box;

  .hca-inspire-icon {
    vertical-align: middle;
    width: $app-icon-size;
    height: $app-icon-size;
  }

  .hca-inspire-title {
    @include set-typeface('MarkPro-Bold');
    font-weight: bold;
    font-size: 42px;
    display: inline-block;
    vertical-align: middle;
    color: $primary;
    line-height: 38px;
    text-align: center;
    margin-left: 35px;
    box-sizing: border-box;
  }
}
