@import '../style/variables';

.top-bar {
  position: fixed;
  top: $zero;
  left: $zero;
  width: 100%;
  min-height: 60px;
  background-color: $header-title;
  color: $white-color;
  text-align: center;
  box-sizing: border-box;
  z-index: 900;

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .name {
    @include set-typeface('MarkPro-Bold');
    font-weight: bold;
  }
}
