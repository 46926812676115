@import '../../../style/variables';

.division-selection {
  .division-search {
    margin-top: 50px;
  }

  .data-not-found {
    margin-top: 70px;
    font-size: 1.8rem;
  }

  .ui {
    &.paginated-table {
      margin-top: 26px;
      max-width: $paginated-table-width;
    }
  }

  .btn-remove {
    cursor: pointer;
  }

  .scrollable {
    max-height: 520px;
    overflow-y: auto;
  }
}

.tab-header {
  margin-top: 16px;

  .btn-empty {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    font-size: 14px;
    text-transform: unset;

    &.btn-divisions-active,
    &.btn-selected-active {
      @include set-typeface('MarkPro-Bold');
      font-weight: bold;
    }
  }
}

.division-selection-button-panel {
  margin-top: 70px;
  font-size: 12px;

  &.with-search-results {
    margin-top: 18px;
  }
}
