@import '../../style/variables';

.sub-header {
  @include set-typeface('MarkPro-Light');
  font-weight: 300;
  display: block;
  font-size: 12px;
  color: $secondary-text;
  line-height: 17px;
  margin: 48px $zero 62px;

  .left-section,
  .right-section {
    display: inline-block;
    vertical-align: top;
  }

  .right-section {
    margin-left: 115px;

    span {
      line-height: 20px;
    }
  }

  .current-role,
  span {
    @include set-typeface('MarkPro-Bold');
    font-weight: bold;
  }
}
