@import '../../style/variables';

.header-wrapper {
  position: relative;
  width: 100%;
  .title {
    @include set-typeface('MarkPro-Bold');
    display: inline-block;
    font-weight: bold;
    font-size: 42px;
    width: 60%;
    color: $header-title;
    line-height: 35px;
    vertical-align: middle;
  }
  .operational-section {
    width: 40%;
    display: inline-block;
    text-align: right;
    .rounded-drop-down {
      display: inline-block;
      vertical-align: middle;
      text-align: left;
    }
  }
}
