@import '../../style/variables';

.search-container {
  .search {
    margin: 50px $zero;
  }
  .result-count {
    @include set-typeface('MarkPro-Light');
    font-weight: 300;
    font-size: 13px;
    line-height: 17px;
    color: $primary-text;
    margin: $zero $zero 32px;
  }
}
