@import '../../../style/variables';

.calendar-panel {
  .selected-date-range {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    font-size: 13px;
    color: $primary;
    line-height: 48px;
    text-align: center;
    letter-spacing: 1px;
    border-top: $light-gray-line;
    border-bottom: $light-gray-line;
    margin: $zero;
    box-sizing: border-box;
  }

  .calendar-btn {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
    color: $primary;
    background: $white-color;
    line-height: 48px;
    border: none;
    outline: none;
    width: 50%;
    box-sizing: border-box;

    &.clear {
      border-right: $light-gray-line;
    }

    &.disabled {
      color: $date-picker-disabled-button;
      cursor: not-allowed;
    }
  }
}
