@import '../../style/variables';

.rounded-drop-down {
  position: relative;
  cursor: pointer;
  width: 180px;
  height: 40px;
  display: inline-block;
  box-sizing: border-box;

  &.full-width {
    width: 100%;
  }

  &.size-medium {
    width: 280px;
  }

  .dropdown-menu {
    display: inline-block;
    background-color: $white-color;
    border-radius: 20px;
    position: absolute;
    top: $zero;
    left: $zero;
    width: inherit;
    overflow: hidden;

    .selected-item {
      padding: 7px 24px;
    }
  }

  .display-text {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    font-size: 13px;
    color: $header-title;
    line-height: 26px;
  }

  .display-icon {
    position: absolute;
    right: $zero;
    padding: $zero 16px;
    line-height: 26px;
  }

  .options-list {
    max-height: $zero;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
    position: relative;

    li {
      padding: 8px 24px;
    }

    .disabled {
      cursor: default;
    }

    .disabled:hover,
    .disabled:active {
      background: none;
    }
  }

  .active {
    max-height: 600px;
    transition: max-height 0.5s ease-in;
  }

  .options-item:hover {
    background-color: $item-hover;
  }

  .options-item:active {
    background-color: $modal-bg;
  }
}
