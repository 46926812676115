@import '../../style/variables';

.user-avatar-wrapper {
  line-height: $avatar-size;
  text-align: center;
  border-radius: 100%;
  max-width: $avatar-size;
  width: $avatar-size;
  max-height: $avatar-size;
  height: $avatar-size;
  background-color: $white-color;
  overflow: hidden;

  img {
    height: 100%;
    width: auto;
    border-radius: 100%;
  }

  &.round-border {
    border: 1px solid $gray;
  }
}
