@import './../../style/variables';

.login-layout {
  height: 100vh;
  position: relative;

  .full-width {
    .alert-bar-message {
      @include set-typeface('MarkPro-Regular');
      font-weight: 400;
      font-size: 16px;
    }
  }

  .login-form {
    margin-top: 10px;
    width: 100%;
  }

  .btn-primary {
    &.btn-text {
      @include set-typeface('MarkPro-Bold');
      color: $white-color;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
    }
  }

  .btn-center {
    margin: $zero;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
