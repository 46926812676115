@import '../../style/variables';

.modal {
  &.modal-allow-remove-action {
    min-width: 580px;
    min-height: 680px;
  }

  .action-modal-header {
    padding-bottom: 50px;
    border-bottom: $light-gray-line;
    line-height: 24px;
    &::after {
      clear: both;
      content: '';
      display: block;
    }
  }
}

.info-panel {
  float: right;

  .info-item {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    color: $secondary-text;
    font-size: 12px;
    line-height: 18px;
  }

  .label {
    @include set-typeface('MarkPro-Bold');
    font-weight: bold;
    display: inline-block;
    margin: $zero 4px 6px $zero;
  }
}

.action-modal-main {
  margin: 36px $zero;

  .comments {
    @include set-typeface('MarkPro-Regular');
    font-weight: 300;
    font-size: 13px;
    line-height: 17px;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    max-width: 100%;
    max-height: 260px;
    margin: 12px $zero;
    padding: 20px;
    border: none;
    outline: none;
  }
}

.action-modal-footer {
  margin: 36px $zero;
  .btn {
    @include set-typeface('MarkPro-Bold');
    font-weight: bold;
    font-size: 13px;
    width: 45%;
    line-height: 20px;
    box-sizing: border-box;
  }
}
