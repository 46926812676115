@import '../../style/variables';

.report-options-group {
  margin: 14px $zero;
  display: inline-block;

  .report-option {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    font-size: 13px;
    color: $primary-text;
    line-height: 40px;
    display: inline-block;
    background-color: $gray;
    padding: $zero 16px;
    min-width: 180px;
    margin: $zero 1px;
    box-sizing: border-box;
  }

  .report-option:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .report-option:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
