// Write all the common css fonts here
@import './_variables';
@mixin font-style {
  font-weight: normal;
  font-style: normal;
}
body {
  @include set-typeface('MarkPro-Regular');
  margin: $zero;
  padding: $zero;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: $body-bg;
  &.no-scroll {
    overflow: hidden;
  }
}
.container {
  margin: $container-margin auto $container-margin 202px;
  box-sizing: border-box;
}
.data-not-found {
  @include set-typeface('MarkPro-Light');
  font-weight: 300;
  font-size: 36px;
  line-height: 35px;
  color: $secondary-text;
}
.hide {
  opacity: 0;
}
