// weight 300
@font-face {
  @include font-style;
  font-family: 'MarkPro-Light';
  src: url('/fonts/MarkPro-Light.otf');
}

// weight 400
@font-face {
  @include font-style;
  font-family: 'MarkPro-Regular';
  src: url('/fonts/MarkPro.otf');
}

// weight 500
@font-face {
  @include font-style;
  font-family: 'MarkPro-Medium';
  src: url('/fonts/MarkPro-Medium.otf');
}

// weight 700 or bold
@font-face {
  @include font-style;
  font-family: 'MarkPro-Bold';
  src: url('/fonts/MarkPro-Bold.otf');
}

// weight 600 or semi-bold
@font-face {
  @include font-style;
  font-family: 'MarkPro-Semibold';
  src: url('/fonts/MarkPro-Book.otf');
}
