@import '../../style/variables';

.badge {
  background-color: $badge-bg;
  color: $white-color;
  line-height: $badge-shape;
  height: $badge-shape;
  width: $badge-shape;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  font-size: 13px;
}
