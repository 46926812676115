@import '../../style/variables';

.action-button-panel {
  .separator-line {
    color: $secondary-text;
    border-bottom: $light-gray-line;
  }
}
.card-view-action {
  display: block;
  border-top: $light-gray-line;
  .separator-line {
    display: none;
  }
  .btn-actions {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    font-size: 16px;
    display: inline-block;
    height: 64px;
    text-transform: unset;
    // if both button are there then have line in between, else (only remove button) remove that line.
    &:first-child:not(:only-of-type) {
      border-right: $light-gray-line;
      border-radius: $zero;
    }
  }
  .w-50 {
    width: 50%;
  }
  .w-100 {
    width: 100%;
  }
}

.action-status {
  &:last-of-type {
    border: none;
  }

  border-bottom: $light-gray-line;
  padding-bottom: 10px;
  &.action-status-card {
    text-align: center;
    height: 64px;
    line-height: 64px;
    padding-bottom: $zero;
    box-sizing: border-box;
  }

  &.allowed-status {
    color: $allow-color;
  }
  &.removed-status {
    color: $remove-color;
  }
}
