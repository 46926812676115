@import '../../../../style/variables';

.summary-table {
  .table-row {
    .table-data {
      min-width: 90px;
      border-bottom: 1px solid $gray;
    }

    .user-card {
      width: 150px;
    }

    .content-block {
      width: 330px;
    }
  }
}
