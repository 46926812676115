@import './../../style/_variables.scss';

.search-bar {
  @include set-typeface('MarkPro-Regular');
  font-weight: 400;
  border: none;
  outline: none;
  height: $search-bar-height;
  width: $search-bar-width;
  border-radius: 20px;
  background-color: $white-color;
  font-size: 16px;
  padding: $zero 18px;
  color: $primary;
  box-sizing: border-box;
}

.search-bar:disabled {
  background: $gray;
}
