@import '../../../style/variables';

.modal-large {
  width: 75%;
  height: 92%;
  min-width: 900px;
  max-width: 1100px;
  min-height: 480px;
  max-height: 800px;
  box-sizing: border-box;
  padding: 65px 5% $zero 5%;
  overflow: hidden;
  .modal-header {
    margin-bottom: 30px;
  }

  .search-bar {
    &.search-bar-large {
      width: 100%;
      margin-top: $zero;
      margin-bottom: 30px;
    }
  }

  .data-not-found {
    font-size: 24px;
  }
}

.modal-body {
  position: relative;
  height: calc(100% - 56px);

  .add-users-table-wrapper {
    height: calc(100% - 145px);
    padding-right: 10px;
    overflow-y: auto;
  }
  .add-users-footer {
    position: absolute;
    background: $body-bg;
    margin: $zero;
    padding-top: 16px;
    padding-bottom: 32px;
    bottom: $zero;
    width: 100%;
    box-sizing: border-box;
    .pagination-btn {
      float: left;
      line-height: 35px;
    }
    .btn {
      margin: $zero;
      float: right;
    }
    &::after {
      clear: both;
    }
  }
}
