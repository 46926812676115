// All constant value for css properties, will be added here.

$zero: 0;
$container-width: 70%;
$container-margin: 100px;
$search-bar-height: 40px;
$search-bar-width: 60%;
$view-icon-size: 28px;
$avatar-size: 42px;
$badge-shape: 25px;
$card-width: 350px;
$paginated-table-width: 80%;
$app-icon-size: 52px;
$nav-width: 100px;
$page-max-width: 1155px;

$body-bg: #f5f7f9;
$primary: #0a233f;
$white-color: #ffffff;
$primary-text: #4f565c;
$secondary-text: #9ca7b1;
$user-card-title: #0b112e;
$header-title: #0b223e;
$gray: #e8ebed;
$alert-bg: #caedec;
$shadow: #0000001a;
$secondary-button-color: #2f9d9f;
$badge-bg: #e05929;
$item-hover: #efefef;
$nav-tooltip-show: #c6cbcf;
$allow-color: #45a13f;
$remove-color: #dd0c0c;

$light-gray-line: 1px solid #e7eaed;

$modal-bg: rgba(79, 86, 92, 0.5);
$loader-shadow: rgba(0, 0, 0, 0.161);
$nav-tooltip-show: #c6cbcf;
$app-icon-size: 52px;
$nav-width: 100px;
$page-max-width: 1155px;
$item-hover: #efefef;
$date-picker-single-selection: #80d6d3;
$date-picker-double-selection: #9ca7b1;
$date-picker-disabled-button: darkgray;

// Font weight setting up
@mixin set-typeface($typeface) {
  font-family: $typeface, sans-serif;
}
