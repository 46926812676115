@import '../../style/variables';

.navigation {
  position: fixed;
  width: $nav-width;
  min-height: 100vh;
  height: 100%;
  background-color: $white-color;
  box-sizing: border-box;
  z-index: 1001;

  .nav-bar {
    position: relative;
    top: 100px;
    left: $zero;
    width: $nav-width;
    text-align: center;
  }
}
