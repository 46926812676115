@import '../../style/variables';

.datepicker-drop-down {
  position: absolute;
  width: 180px;
  display: inline-block;
  box-sizing: border-box;

  &.full-width {
    width: 100%;
  }

  &.size-medium {
    width: 270px;
  }

  .dropdown-menu {
    display: inline-block;
    background-color: $white-color;
    border-radius: 20px;
    position: relative;
    top: $zero;
    left: $zero;
    width: inherit;
    overflow: hidden;
    margin-bottom: 150px;
  }

  .options-list {
    cursor: pointer;
    max-height: $zero;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
    position: relative;

    li {
      padding: 8px 24px;
    }

    .disabled {
      cursor: default;
    }

    .disabled:hover,
    .disabled:active {
      background: none;
    }
  }

  .active {
    max-height: 600px;
    transition: max-height 0.5s ease-in;
  }

  .options-item:hover {
    background-color: $item-hover;
  }

  .options-item:active {
    background-color: $modal-bg;
  }
}
