@import '../../../style/variables';

.nav-item {
  position: relative;
  margin: 50px auto;

  // Remove first child margin
  &:first-child {
    margin: $zero auto;
  }

  // Hide active logo behind inactive logo
  .active-logo {
    opacity: $zero;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .inactive-logo {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
  // On active toggle active logo and inactive logo
  .active {
    .active-logo {
      opacity: 1;
    }
    .inactive-logo {
      opacity: $zero;
    }
  }

  // Tooltip
  .nav-tooltip {
    @include set-typeface('MarkPro-Regular');
    font-weight: 400;
    position: absolute;
    top: 50%;
    left: $nav-width;
    transform: translate(-10%, -50%);
    min-width: 150px;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 17px;
    color: $primary-text;
    background-color: $white-color;
    border-radius: 20px;
    box-shadow: $zero $zero 10px $zero $nav-tooltip-show;
    box-sizing: border-box;
    opacity: $zero;
    pointer-events: none;
    transition: all 0.5s ease-in-out;

    // Triangle pointer for tooltip
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: $zero;
      transform: translate(-25%, -50%) rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: $white-color;
    }
  }

  // Badge
  .notification-badge {
    position: absolute;
    top: -10px;
    right: 20px;
  }

  .logo:hover {
    & + .nav-tooltip {
      opacity: 1;
    }
  }
}
