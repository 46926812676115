@import '../../style/variables';

.alert-bar {
  position: fixed;
  top: $zero;
  left: $zero;
  width: 100%;
  min-height: 60px;
  background-color: $alert-bg;
  padding: 18px 30px 18px 130px;
  box-sizing: border-box;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  &.alert-dismissed {
    opacity: 0;
    transform: translateY(-100%);
  }

  .alert-bar-message {
    @include set-typeface('MarkPro-Light');
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    color: $header-title;
  }

  .alert-bar-icon {
    float: left;
    margin-right: 12px;
  }
}
