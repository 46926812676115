@import '../../style/variables';

.checkbox-label {
  display: inline-block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 24px;
  width: 24px;
  clear: both;
  box-sizing: border-box;
}

.checkbox-label input {
  position: absolute;
  opacity: $zero;
  cursor: pointer;
}

.checkbox-label .checkbox-custom {
  position: absolute;
  top: $zero;
  left: $zero;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  border: 1px solid $primary;
  box-sizing: border-box;
}

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: transparent;
  border-radius: 5px;
  transform: rotate($zero) scale(1);
  opacity: 1;
  border: 1px solid $primary;
  box-sizing: border-box;
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: '';
  left: 12px;
  top: 12px;
  height: $zero;
  width: $zero;
  border-radius: 5px;
  border: solid $primary;
  border-width: $zero 3px 3px $zero;
  transform: rotate($zero) scale($zero);
  opacity: 1;
  transition: all 0.3s ease-out;
  box-sizing: border-box;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid $primary;
  border-width: $zero 2px 2px $zero;
  background-color: transparent;
  border-radius: $zero;
  box-sizing: border-box;
}

// For Ripple Effect
.checkbox-label .checkbox-custom::before {
  position: absolute;
  content: '';
  left: 10px;
  top: 10px;
  width: $zero;
  height: $zero;
  border-radius: 5px;
  border: 2px solid $primary;
  transform: scale($zero);
  box-sizing: border-box;
}

.checkbox-label input:checked ~ .checkbox-custom::before {
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  transform: scale(2);
  opacity: $zero;
  z-index: 999;
  transition: all 0.3s ease-out;
  box-sizing: border-box;
}
