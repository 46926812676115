.card-view-container {
  display: block;
  width: 100%;

  .staggered-grid {
    display: inline-block;
    width: 30%;
    vertical-align: top;

    // First row cards
    &.col-1 {
      margin-right: 5%;
    }
    // Third row cards
    &.col-3 {
      margin-left: 5%;
    }
  }
}
