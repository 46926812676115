@import '../../style/variables';

.admin-moderate {
  header {
    position: relative;

    h1 {
      @include set-typeface('MarkPro-Bold');
      font-weight: bold;
      font-size: 36px;
      color: $header-title;
    }
  }

  main {
    .paginated-table {
      max-width: $page-max-width;
    }
  }

  .search-bar {
    margin: 50px $zero;
  }

  .delete-icon {
    vertical-align: middle;
    cursor: pointer;
    width: 25px;
    height: 30px;
  }

  .btn {
    margin: $zero 12px;
    font-size: 12px;
  }

  footer {
    max-width: $page-max-width;
    margin: 25px $zero;
    position: relative;

    .btn-container {
      display: inline-block;
      right: $zero;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
