.exif-img {
  width: 100%;
}

.fade-in {
  animation-name: fadeIn;
}

.animated {
  animation-duration: 0.75s;
  animation-fill-mode: both;
}

.image-placeholder {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 20px;
  min-height: 20px;
  .img-loader {
    position: absolute;
    left: calc(50% - 12.5px);
    top: calc(50% - 12.5px);
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 50%;
    border-top: 2px solid lightgray;
    width: 25px;
    height: 25px;
    animation: spin 0.7s linear infinite;
  }
}

.display-none {
  display: none;
}

.display-inline-block {
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
