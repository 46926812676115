@import '../../../style/variables';

.view-by {
  display: inline-block;
  vertical-align: middle;
  .icon {
    margin: $zero 15px $zero 32px;
    width: $view-icon-size;
    height: $view-icon-size;
    cursor: pointer;

    // image
    object {
      width: 100%;
      height: 100%;
    }

    &:last-of-type {
      margin: $zero;
    }
  }
}
