@import '../../style/variables';

.user-card {
  display: inline-block;
  .user-avatar {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }
  .user-details {
    display: inline-block;
    vertical-align: middle;
    .user-name {
      @include set-typeface('MarkPro-Semibold');
      font-weight: 600;
      color: $user-card-title;
      font-size: 14px;
      margin-bottom: 4px;
    }
    .user-info {
      @include set-typeface('MarkPro-Medium');
      font-weight: 500;
      position: relative;
      top: -5px;
      color: $secondary-text;
      font-size: 12px;
    }
  }
  .comment-icon {
    display: inline-block;
    margin-left: 13px;
    vertical-align: top;
  }

  &.profile-link-enabled {
    cursor: pointer;
  }
}
