@import '../../style/variables';

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1004;
  left: $zero;
  top: $zero;
  background-color: $modal-bg;
}
